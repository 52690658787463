import React from 'react';
import $ from 'jquery'; 
import Humberger from './Humberger';

const Nav = () => {
  
  return (
    <header className="header-area header-sticky wow slideInDown" data-wow-duration="0.75s" data-wow-delay="0s">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="main-nav">
              {/* ***** Logo Start ***** */}
              <a href="#top" className="logo">
                <img src="assets/images/logo.svg" style={{ width: '60px' }} alt="Logo" />
              </a>
              {/* ***** Logo End ***** */}
              {/* ***** Menu Start ***** */}
              <ul className="nav">
                <li className="scroll-to-section"><a href="#top">Accueil</a></li>
                <li className="scroll-to-section"><a href="#services">Services</a></li>
                <li className="scroll-to-section"><a href="#about">A Propos</a></li>
            
                {/* <li className="scroll-to-section"><a href="#video">Abonner</a></li> */}
                {/* <li className="scroll-to-section"><a href="#contact">Contact</a></li> */}
                <li className="scroll-to-section">
                  <div className="main-red-button-hover">
                    <a href="#contact">Contactez-Nous </a>
                  </div>
                </li>
               
              </ul>
              <a className='menu-trigger'>
              <Humberger/>
              </a>
                
              
              {/* ***** Menu End ***** */}
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Nav;
