import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import './service.css'
const Services = () => {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        850:{
          items:3,
        },
        1000: {
            items: 4,

        }
    },
};
  return (
    <div id="services" className="our-services section">
      <div className="services-right-dec">
        <img  src="assets/images/services-left-dec.png" alt="" />
      </div>
      <div className="container">
        <div className="services-left-dec">
          <img style={{height:"450px"}} src="assets/images/services-left-dec.png" alt="" />
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="section-heading">
              <h2>
              Notre <em>Engagement</em>  Fournir l'Excellence à travers
              </h2>
              <br/>
              <span>Nos Services</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <OwlCarousel  items={3} {...options}>
              <div className="item">
                <div className="icon">
                  <img src="assets/images/medical billing and claims management.png" style={{width:"60px"}} />
                </div>
                {/* <br/>
                <br/> */}
                <h4>la gestion de rendezvous</h4>           
                <p>Votre Parcours de Santé, un Rendez-vous à la Fois</p>
              </div>

              <div className="item">
                <div className="icon">
                  <img src="assets/images/inpatient management.png" style={{width:"60px"}} />
                </div>
                {/* <br/>
                <br/> */}
                <h4>la gestion des patients</h4>             
                <p>Elle implique la coordination des soins, le suivi des informations médicales</p>
              </div>

              <div className="item">
                <div className="icon">
                  <img src="assets/images/medical billing and claims management.png" style={{width:"60px"}}/>
                </div>
                 <br/>
                <br/>
                <h4>Facturation médicale et gestion des réclamations</h4>
                <p>Processus d'émission de factures pour les services médicaux </p>
              </div>
              
              <div className="item">
                <div className="icon">
                  <img src="assets/images/health information exchange.png" style={{width:"50px"}}/>
                </div>
                 <br/>
                <br/>
                <h4>Échange d'informations de santé</h4>
                <p>Cet échange permet une coordination efficace des soins, une prise de décision éclairée et une amélioration de la qualité des soins de santé</p>
              </div>

              <div className="item">
                <div className="icon">
                  <img src="assets/images/PACS.png" style={{width:"60px"}}/>
                </div>
                 <br/>
                <br/>
                <br/>
                <h4>Système d'archivage </h4>
                <p>il  permet d'acquerir , stocker, gérer, distribuer et visualiser des images médicales</p>
              </div>

              <div className="item">
                <div className="icon">
                  <img src="assets/images/electronic perscription.png" style={{width:"60px"}}/>
                </div>
                 <br/>
                <br/>
                <h4>Prescription électronique</h4>
                <p>il permet de remplacer les prescriptions sur papier</p>
              </div>

              <div className="item">
                <div className="icon">
                  <img src="assets/images/telemedicine.png" style={{width:"60px"}}/>
                </div>
                 <br/>
                <br/>
                <h4>Télémédecine</h4>
                <p>il permet de consulter des professionnels de la santé sans nécessiter de rendez-vous physiques en personne</p>
              </div>

              <div className="item">
                <div className="icon">
                  <img src="assets/images/patient portal.png" style={{width:"60px"}}/>
                </div>
                 <br/>
                <br/>
                <h4>Portail patient</h4>
                <p> permet aux patients d'accéder à leurs informations médicales </p>
              </div>

              <div className="item">
                <div className="icon">
                  <img src="assets/images/inventory management.png" style={{width:"60px"}}/>
                </div>
                 <br/>
                <br/>
                <h4>Gestion des stocks</h4>
                <p>est'un processus essentiel dans la gestion des opérations d'une entreprise</p>
              </div>

              <div className="item">
                <div className="icon">
                  <img src="assets/images/quality management.png" style={{width:"60px"}}/>
                </div>
                 <br/>
                <br/>
                <h4>Gestion de la qualité</h4>
                <p>permet de garantir que les produits, les services ou les processus d'une organisation répondent aux normes </p>
              </div>

              <div className="item">
                <div className="icon">
                  <img src="assets/images/assurance medicale.png" style={{width:"60px"}}/>
                </div>
                 <br/>
                <br/>
                <h4>Gestion de l'assurance et des réclamations</h4>
                <p>permet de gérer les contrats d'assurance, les demandes de couverture </p>
              </div>

              <div className="item">
                <div className="icon">
                  <img src="assets/images/inpatient management.png" style={{width:"60px"}}/>
                </div>
                 <br/>
                <br/>
                <h4>La gestion des patients hospitalisés</h4>
                <p>permet d'assurer des soins de haute qualité et une prise en charge efficace des patients</p>
              </div>
              
            </OwlCarousel>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Services;
