import React from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs.sendForm('service_qn695bf', 'template_j6d00dw', e.target, 'alOUZalXHqAItHqUt')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };
  return (
    <div id="contact" className="contact-us section" style={{marginTop:'120px'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="section-heading">
              <h2>
                {/* Feel free to <em>Contact</em> us via the <span>HTML form</span> */}
                N'hésitez pas à <em> Nous Contacter </em> via le <em>formulaire </em>
              </h2>
              <div id="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3235.0954193071875!2d10.62648407627636!3d35.82213452222005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd8b163fd48ee3%3A0xc01b2b16bd714af2!2sTsast%20Tunisia%20Saudi%20smart%20technology!5e0!3m2!1sfr!2stn!4v1697885717965!5m2!1sfr!2stn"
                  width="100%"
                  height="360px"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen
                ></iframe>
              </div>
              <div className="info">
                <span>
                  <i className="fa fa-phone"></i> <a href="#">(216) 29 750 723 </a>
                </span>
                <span>
                  <i className="fa fa-envelope"></i> <a  href="#"> info@company.com</a>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-5 align-self-center">
            <form onSubmit={sendEmail} id="contact" action="" method="get">
              <div className="row">
                <div className="col-lg-12">
                  <fieldset>
                    <input type="name" name="from_name" id="name" placeholder="Nom" autoComplete="on" required />
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <input type="surname" name="from_prenom" id="surname" placeholder="Prénom" autoComplete="on" required />
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <input type="text" name="from_email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Votre email" required />
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <input type="text" name="message" id="website" placeholder="Message" required />
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <button type="submit" id="form-submit" className="main-button">
                    soumettre une demande
                    </button>
                  </fieldset>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="contact-dec">
        <img src="assets/images/contact-dec.png" alt="" />
      </div>
      <div className="contact-left-dec">
        {/* <img src="assets/images/contact-left-dec.png" alt="" /> */}
      </div>
    </div>
  );
};

export default Contact;
