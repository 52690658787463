import React from 'react';

const About = () => {
  return (
    <div id="about" className="about-us section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 align-self-center">
            <div className="left-image">
              <img src="assets/images/about-left-image.png" alt="Two Girls working together" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="section-heading">
              <h2>
                {/* Grow your website with our <em>SEO Tools</em> &amp; <span>Project</span> Management */}
                notre équipe cherchent à faciliter la vie des spécialistes de santé 
              </h2>
              <p>
              Notre plateforme médicale, établie en 2023, a été fondée par un groupe  passionnés de la santé et d'experts en technologie partageant une vision commune de révolutionner l'accessibilité et la prestation des soins de santé.
              </p>
              <div className="row">
                <div className="col-lg-4">
                  <div className="fact-item">
                    <div className="count-area-content">
                      <div className="icon">
                        <img src="assets/images/service-icon-01.png" alt="" />
                      </div>
                     
                      <div className="count-title">Flexibilité et mobilité</div>
                      <p>Les médecins peuvent travailler à partir de divers endroits, ce qui peut êtreutile en cas de déplacement, de mission humanitaire ou de besoin d'assistance en cas de catastrophe.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="fact-item">
                    <div className="count-area-content">
                      <div className="icon">
                        <img src="assets/images/service-icon-02.png" alt="" />
                      </div>
                      <div className="count-title">Dossiers médicaux électroniques (DME) </div>
                      <p>Stockage sécurisé des dossiers médicaux des patients.
                      Accès rapide aux informations de santé essentielles.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="fact-item">
                    <div className="count-area-content">
                      <div className="icon">
                        <img src="assets/images/service-icon-03.png" alt="" />
                      </div>
                      <div className="count-title">Diagnostic Précis et Rapide</div>
                      <p>L'IA analyse les données médicales du patient pour proposer des diagnostics préliminaires.
                      Réduction des erreurs de diagnostic.
                      Aide à l'identification des maladies rares et complexes.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
