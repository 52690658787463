

import Nav from './components/Nav';
import Home from './components/Home';
import Service from './components/Service';
import About from './components/About';
import Portfolio from './components/Portfolio';

import Contact from './components/Contact';
import Footer from './components/Footer';
import Video from './components/Video';

function App() {
  return (
    <div className="App">
      <Nav/>
      <Home/>
      <Service/>
      <About/>
      <Portfolio/>
      {/* <Video/> */}
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
