import { useState , useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from './Nav';

function Humberger() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const handleScroll = () => {
      if (show) {
        setShow(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [show]);

  return (
    <>
      <span style={{width:"100%"}} variant="primary" onClick={handleShow}>
        Launch
      </span>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><a href="#top" style={{textAlign: "center"}} className="logo">
                <img  src="assets/images/logo.svg" style={{ width: '60px'}} alt="Logo" />
              </a></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <nav style={{background:""}} className="main-nav">
              {/* ***** Logo Start ***** */}
              
              {/* ***** Logo End ***** */}
              {/* ***** Menu Start ***** */}
              <div>
                <a style={{color: 'black' , textAlign:"center"}}href="#top"> <h4>Accueil</h4></a><hr style={{color:"#66afa5"}}/> 
                <a style={{color: 'black' , textAlign:"center"}}href="#services"><h4>Services</h4></a><hr style={{color:"#66afa5"}}/> 
                <a style={{color: 'black' , textAlign:"center"}}href="#about"><h4>A Propos</h4></a><hr style={{color:"#66afa5"}}/> 
                

                {/* <a style={{color: 'black' , textAlign:"center"}}href="#contact"><h4>Contact</h4></a><hr style={{color:"#66afa5"}}/>   */}
                {/* <a style={{color: 'black' , textAlign:"center"}}href="#video"><h4>Abonner</h4></a><hr style={{color:"#66afa5"}}/>  */}
                
                
                  <div style={{textAlign:"center"}} className="main-red-button-hover">
                    <a style={{color: 'black' , textAlign:"center" , width:"10rem"}}href="#contact">Contactez-Nous </a>
                  </div>
               
              </div>
             
                
              
              {/* ***** Menu End ***** */}
            </nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Humberger;