import React from "react";

const Footer = () => {
  return (
    <>
      <div className="footer-dec">
        <img
          src="assets/images/footer-dec.png"
          alt
          style={{ height: "250px" }}
        />
      </div>

      <footer style={{ marginTop: "50px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">       
                <div className="about footer-item">
                <div className="logo">
                  <a href="#">
                    <img
                      style={{ marginLeft: "40px", width: "80px" }}
                      src="assets/images/logo.svg"
                      alt="Onix Digital TemplateMo"
                    />
                  </a>
                </div>
                {/* <a href="#">info@company.com</a> */}
                <ul >
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  {/* <li><a href="#"><i className="fa fa-twitter"></i></a></li>
              <li><a href="#"><i className="fa fa-behance"></i></a></li> */}
                  <li>
                    <a href="#">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
                </div>    
            </div>
            <div className="col">
              <div className="services footer-item">
                <h4 style={{ marginLeft: "30px" }}> Liens utiles</h4>
                <ul>
                  <li>
                    <a href="#top">Accueil</a>
                  </li>
                  <li>
                    <a href="#services">Services</a>
                  </li>
                  <li>
                    <a href="#about">A Propos</a>
                  </li>
                  <li>
                    <a href="#portfolio">Abonnement</a>
                  </li>

                  <li>
                    <a href="#contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col">
              <div className="community footer-item">
                <h4 style={{ marginLeft: "30px" }}>Communauté</h4>
                <ul>
                  <li>
                    <a href="#">Réseau de santé</a>
                  </li>
                  <li>
                    <a href="#">Réseau clinique</a>
                  </li>
                  <li>
                    <a href="#">Société médicale</a>
                  </li>
                  <li>
                    <a href="#">Partage d'informations médicales</a>
                  </li>
                  <li>
                    <a href="#">Échange d'informations en santé</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-3">
          <div className="subscribe-newsletters footer-item">
            <h4>S'abonner</h4>
            <p>Recevez nos dernières nouvelles et idées dans votre boîte de réception</p>
            <form action="#" method="get">
              <input type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Votre Email" required />
              <button type="submit" id="form-submit" className="main-button "><i className="fa fa-paper-plane-o"></i></button>
            </form>
          </div>
        </div> */}
            <div className="col-lg-12">
              <div className="copyright">
                <p>
                  Copyright © 2023 PromedFlow., Tous droits réservés. <br />
                  Conçu par{" "}
                  <a
                    rel="nofollow"
                    href="https://tsast.net/"
                    
                  >
                    Tsast
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
