import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import "./portfolio.css";
const Portfolio = () => {
  return (
    <div id="portfolio" className="our-portfolio section">
      <div className="portfolio-left-dec">
        {/* <img src="assets/images/portfolio-left-dec.png" alt="" /> */}
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="owl-carousel owl-portfolio">
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-01.jpg" alt="" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a
                        rel="sponsored"
                        href="https://templatemo.com/tm-564-plot-listing"
                        target="_parent"
                      >
                        <h4>First Project</h4>
                      </a>
                      <span>Plot Listing</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-02.jpg" alt="" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a href="#">
                        <h4>Project Two</h4>
                      </a>
                      <span>SEO &amp; Marketing</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-03.jpg" alt="" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a
                        rel="sponsored"
                        href="https://templatemo.com/tm-562-space-dynamic"
                        target="_parent"
                      >
                        <h4>Third Project</h4>
                      </a>
                      <span>Space Dynamic SEO</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-04.jpg" alt="" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a href="#">
                        <h4>Project Four</h4>
                      </a>
                      <span>Website Marketing</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-01.jpg" alt="" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a href="#">
                        <h4>Fifth Project</h4>
                      </a>
                      <span>Digital Assets</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-02.jpg" alt="" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a href="#">
                        <h4>Sixth Project</h4>
                      </a>
                      <span>SEO &amp; Marketing</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-03.jpg" alt="" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a href="#">
                        <h4>7th Project</h4>
                      </a>
                      <span>SEO &amp; Marketing</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-04.jpg" alt="" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a href="#">
                        <h4>8th Project</h4>
                      </a>
                      <span>SEO &amp; Marketing</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-01.jpg" alt="" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a href="#">
                        <h4>9th Project</h4>
                      </a>
                      <span>SEO &amp; Marketing</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-02.jpg" alt="" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a href="#">
                        <h4>Project Ten</h4>
                      </a>
                      <span>SEO &amp; Marketing</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-03.jpg" alt="" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a href="#">
                        <h4>Project Eleven</h4>
                      </a>
                      <span>SEO &amp; Marketing</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-04.jpg" alt="" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a href="#">
                        <h4>12th Project</h4>
                      </a>
                      <span>SEO &amp; Marketing</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="pricing" className="pricing-tables">
        <div className="tables-left-dec">
          <img src="assets/images/tables-left-dec.png" alt="" />
        </div>
        <div className="tables-right-dec">
          <img src="assets/images/tables-right-dec.png" alt="" />
        </div>
        {/* <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="section-heading">
                <h2>
                
                  Choisissez un plan adapté à vos futurs projets
                </h2>
                <span>Nos Plans</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 card-soon">
              <div className="item first-item">
                <h4>Plan gratuit</h4>
         
                <ul>
                  <li>10 Projects</li>
                  <li>100 GB space</li>
                  <li>20 SEO checkups</li>
                  <li>Basic Support</li>
                </ul>
                <div className="main-blue-button-hover">
                  <a href="#">Commencer</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item second-item">
                <h4>plan standars</h4>

                <ul>
                  <li>20 Projects</li>
                  <li>200 GB space</li>
                  <li>50 SEO checkups</li>
                  <li>Pro Support</li>
                </ul>
                <div className="main-blue-button-hover">
                  <a href="#">Obtenez maintenant</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item third-item">
                <h4>plan avancé</h4>
     
                <ul>
                  <li>30 Projects</li>
                  <li>300 GB space</li>
                  <li>100 SEO checkups</li>
                  <li>Best Support</li>
                </ul>
                <div className="main-blue-button-hover">
                  <a href="#">Acheter maintenant</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Portfolio;
