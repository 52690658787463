import React, { useEffect, useState } from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Home.css"
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player';
const Home = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="main-banner" id="top">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6 align-self-center">
              
             <>
      <button className="videoo"  onClick={handleShow}>
      <a><img className='logooo' src="assets/images/playy.svg" /></a> 
      </button>
      
      <Modal   show={show} onHide={handleClose}>
       
        <Modal.Body className='modalll' style={{background: "black" , position: "absolute", }}  >
          <button style={{background: "none" , color: "white" , border : "none" , fontSize: "20px" , fontWeight: "600"}}  onClick={handleClose} >X</button>
        <ReactPlayer
                className='react-player'
                url='https://www.youtube.com/watch?v=JNDqGsHpqVM'
                controls={true}
                width='100%'
                height='90%'
            />
        </Modal.Body>
      </Modal>
    </>
                <OwlCarousel className="owl-theme" loop margin={10} items={1} >
                  
                 
                    <div className="item header-text">
                      <h6 style={{color: "white"}}>bienvenus à promedflow</h6>
                      <h2 id='tittle' >
                      Explorez notre  <em style={{color:"white"}}>Plateforme Médicale </em> 
                      </h2>
                      <p>
                      c'est une plateforme qui facilitent la gestion efficace et la prestation des services de santé

                      </p>
                      <div className="down-buttons">
                        <div className="main-blue-button-hover">
                          <a href="#contact">Contacter Nous</a>
                        </div>
                        
                      </div>
                    </div>
                  
                  <div className="item header-text">
                    <h6 style={{color: "white"}}>bienvenus à promedflow</h6>
                    <h2 id='tittle'>
                    La santé est <em style={{color:"white"}}>le trésor </em> le plus précieux
                     
                    </h2>
                    <p>
                    la santé numérique est devenue essentielle pour répondre aux besoins médicaux de manière efficace
                    </p>
                    <div className="down-buttons">
                      <div className="main-blue-button-hover">
                        <a href="#contact">Contacter Nous</a>
                      </div>
                      
                    </div>
                  </div>

                  <div className="item header-text">
                    <h6 style={{color: "white"}}>bienvenus à promedflow</h6>
                    <h2 id='tittle'>
                     
                     le plus précieux que nous ayons est <em style={{color:"white"}}>la santé </em>

                    </h2>
                    <p>
                    
                      <a
                        rel="nofollow"
                        href="https://www.paypal.me/templatemo"
                        target="_blank"
                      >
                        
                      </a>{" "}
                      bénéficez de notre plateforme pour trouver un professionnel de santé en toute discrétion et rapidement
                    </p>
                    <div className="down-buttons">
                      <div className="main-blue-button-hover">
                        <a href="#contact">Contacter Nous</a>
                      </div>
                     
                    </div>
                  </div>

                </OwlCarousel>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
